var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Progress", { attrs: { name: "Statistics" } }),
      _vm._v(" "),
      _c("div", [
        _c("label", [_vm._v("Issues created since:")]),
        _vm._v(" "),
        _c("div", { staticClass: "field has-addons" }, [
          _c("div", { staticClass: "control" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.since,
                  expression: "since",
                },
              ],
              staticClass: "input",
              attrs: { type: "date" },
              domProps: { value: _vm.since },
              on: {
                change: function ($event) {
                  return _vm.load()
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.since = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "control" }, [
            _c(
              "button",
              {
                staticClass: "button is-info",
                on: {
                  click: function ($event) {
                    return _vm.load(true)
                  },
                },
              },
              [_vm._v("\n          Beginning\n        ")]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.history !== null
        ? _c("chartist", {
            attrs: {
              type: "Line",
              data: _vm.history,
              options: _vm.chartOptions,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.stats
        ? _c("div", [
            _vm.stats
              ? _c("table", { staticClass: "table is-fullwidth" }, [
                  _c("thead", [
                    _c("tr", [
                      _c(
                        "th",
                        [
                          _c("Choice", {
                            attrs: {
                              choices: _vm.repositories,
                              name: "repository",
                            },
                            on: {
                              "new-choice": function ($event) {
                                return _vm.use_filter("repository", $event)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        [
                          _c("Choice", {
                            attrs: { choices: _vm.analyzers, name: "analyzer" },
                            on: {
                              "new-choice": function ($event) {
                                return _vm.use_filter("analyzer", $event)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        [
                          _c("Choice", {
                            attrs: { choices: _vm.checks, name: "check" },
                            on: {
                              "new-choice": function ($event) {
                                return _vm.use_filter("check", $event)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("th", [
                        _c(
                          "button",
                          {
                            staticClass: "button is-info",
                            attrs: { disabled: _vm.sortColumn == "total" },
                            on: {
                              click: function ($event) {
                                return _vm.sort_by("total")
                              },
                            },
                          },
                          [_vm._v("\n              Detected\n            ")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("th", [
                        _c(
                          "button",
                          {
                            staticClass: "button is-info",
                            attrs: {
                              disabled: _vm.sortColumn == "publishable",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sort_by("publishable")
                              },
                            },
                          },
                          [_vm._v("\n              Publishable\n            ")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Actions")]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.stats_filtered, function (stat) {
                      return _c(
                        "tr",
                        { class: { publishable: stat.publishable > 0 } },
                        [
                          _c("td", [_vm._v(_vm._s(stat.repository))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(stat.analyzer))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(stat.check))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(stat.total))]),
                          _vm._v(" "),
                          _c("td", [
                            stat.publishable > 0
                              ? _c("strong", [_vm._v(_vm._s(stat.publishable))])
                              : _c("span", { staticClass: "has-text-grey" }, [
                                  _vm._v("0"),
                                ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "button is-small",
                                  attrs: {
                                    to: {
                                      name: "check",
                                      params: {
                                        repository: stat.repository,
                                        analyzer: stat.analyzer,
                                        check: stat.check,
                                      },
                                    },
                                  },
                                },
                                [_vm._v("View issues")]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e(),
          ])
        : _c("div", { staticClass: "notification is-info" }, [
            _vm._v("Loading tasks..."),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }